<!--版权声明-->
<template>
    <div class="page">
        <HeadContent>
            <img slot="bgImg" src="../../assets/common/topTitleBg.png" style="margin-top: 70px">
            <div slot="title" style="width: 100%">{{title}}</div>
        </HeadContent>
        <div class="container">
            <div class="bbbox">
                <div class="ltitle">{{title}}</div>
                <div class="content" v-show="title=='法律声明'">
                    <div class="gxtime">上次更新时间：2021年2月1日<br>
                        任何人士使用本网站前，请仔细阅读下列条款，您继续进入或使用本网站，即代表您同意遵守下列条款。
                    </div>
                    <ul>
                        <li class="ul-title">权利声明</li>
                        <div>
                            1、西部食谷（重庆德感科技创业有限公司）对网站享有完全权利，可在任何时候变更此网站的协议、操作条件和操作方法，并有权随时不经声明更改网站上的任何信息，本网站的使用者不享有任何权利追究因对西部食谷（重庆德感科技创业有限公司）的信任而产生的侵权或者法律责任。<br>
                            2、西部食谷官网内所有数据、产品及其他信息（包括文字、图标、商标、图片、照片、音频、视频、图表、色彩组合、版面设计、视觉体系）的所有权、著作权及其他权利，均归西部食谷（重庆德感科技创业有限公司）独立拥有或与相关内容提供者共同拥有，部分展示信息（包括入驻企业信息、商标）由西部食谷入驻企业独立所有。<br>
                            3、此种权利受到《中华人民共和国民法通则》、《中华人民共和国著作权法》、《中华人民共和国反不正当竞争法》及其他法律、法规、规范性法律文件及相关国际条约的保护，任何对上述权利的侵犯均有可能导致承担民事、行政或刑事责任。<br>
                            4、您不得将本网站所提供的信息，用于任何不合法的目的。不得对本网站的全部或部分页面、内容做商业性的链接和利用，也不得歪曲和篡改本网站。<br>
                            5、未经西部食谷（重庆德感科技创业有限公司）的许可，任何人不得以任何形式的程序、设备，通过监视、复制、传播、展示、镜像、上传、下载等手段擅自使用西部食谷官网上的任何内容。如有宣传、展示等任何正轨使用需要，您必须取得西部食谷（重庆德感科技创业有限公司）或西部食谷入驻企业的授权。<br>
                        </div>

                        <li class="ul-title">免责声明</li>
                        <div>
                            1、西部食谷官网产业互联网平台所提供的任何产品图片、性能或者价格仅供参考，并不构成对相关产品生产商、销售商的约束。本网站的任何信息都不应当被视为要约或者承诺。<br>
                            2、尽管已经尽可能努力保证网站信息来源的准确性，但西部食谷（重庆德感科技创业有限公司）不承担因本网站内容的错误、缺失或因利用本网站信息而产生的任何责任。本网站所有信息均表明依据事实提供，对相关内容的完整性、准确性、时效性及利用网站信息所产生的后果不承担任何责任，西部食谷（重庆德感科技创业有限公司）对此不作任何形式的明示或暗示担保，包括但不限于为特定目的而做出的担保。<br>
                        </div>
                    </ul>
                </div>
                <div class="content" v-show="title=='隐私条款'">
                </div>
                <div class="content" v-show="title=='版权声明'">
                    <div class="gxtime">上次更新时间：2021年2月1日<br>
                        任何人士使用本网站前，请仔细阅读下列条款，您继续进入或使用本网站，即代表您同意遵守下列条款。
                    </div>
                    <ul>
                        <li class="ul-title">权利声明</li>
                        <div>
                            1、西部食谷（重庆德感科技创业有限公司）对网站享有完全权利，可在任何时候变更此网站的协议、操作条件和操作方法，并有权随时不经声明更改网站上的任何信息，本网站的使用者不享有任何权利追究因对西部食谷（重庆德感科技创业有限公司）的信任而产生的侵权或者法律责任。<br>
                            2、西部食谷官网内所有数据、产品及其他信息（包括文字、图标、商标、图片、照片、音频、视频、图表、色彩组合、版面设计、视觉体系）的所有权、著作权及其他权利，均归西部食谷（重庆德感科技创业有限公司）独立拥有或与相关内容提供者共同拥有，部分展示信息（包括入驻企业信息、商标）由西部食谷入驻企业独立所有。<br>
                            3、此种权利受到《中华人民共和国民法通则》、《中华人民共和国著作权法》、《中华人民共和国反不正当竞争法》及其他法律、法规、规范性法律文件及相关国际条约的保护，任何对上述权利的侵犯均有可能导致承担民事、行政或刑事责任。<br>
                            4、您不得将本网站所提供的信息，用于任何不合法的目的。不得对本网站的全部或部分页面、内容做商业性的链接和利用，也不得歪曲和篡改本网站。<br>
                            5、未经西部食谷（重庆德感科技创业有限公司）的许可，任何人不得以任何形式的程序、设备，通过监视、复制、传播、展示、镜像、上传、下载等手段擅自使用西部食谷官网上的任何内容。如有宣传、展示等任何正轨使用需要，您必须取得西部食谷（重庆德感科技创业有限公司）或西部食谷入驻企业的授权。<br>
                        </div>

                        <li class="ul-title">免责声明</li>
                        <div>
                            1、西部食谷官网产业互联网平台所提供的任何产品图片、性能或者价格仅供参考，并不构成对相关产品生产商、销售商的约束。本网站的任何信息都不应当被视为要约或者承诺。<br>
                            2、尽管已经尽可能努力保证网站信息来源的准确性，但西部食谷（重庆德感科技创业有限公司）不承担因本网站内容的错误、缺失或因利用本网站信息而产生的任何责任。本网站所有信息均表明依据事实提供，对相关内容的完整性、准确性、时效性及利用网站信息所产生的后果不承担任何责任，西部食谷（重庆德感科技创业有限公司）对此不作任何形式的明示或暗示担保，包括但不限于为特定目的而做出的担保。<br>
                        </div>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                title: this.$route.query.title
            }
        }
    }
</script>

<style scoped lang="scss">
    li {
        list-style: none
    }

    li:before {
        content: "";
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 50%;
        background: #707070;
        vertical-align: middle;
        margin-right: 14px;
    }

    .bbbox {
        box-sizing: border-box;
        width: 100%;
        padding: 0 100px;
    }

    .ltitle {
        margin-top: 100px;
        font-size: 36px;
        font-weight: 500;
        color: #333333;
    }

    .gxtime {
        margin: 36px 0;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
    }

    .content {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #606060;
        ul {
            margin-top: 10px;
        }
    }

    .ul-title {
        margin-bottom: 4px;
        margin-top: 10px;
        position: relative;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #606060;
        transform: translateX(-30px);
    }
</style>